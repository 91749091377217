<template>
  <Sidebar
    v-if="props.isOpen"
    :is-open="props.isOpen"
    :with-backdrop="props.isOpen"
    is-right-side
    @on-backdrop-click="$emit('onBackdropClick')"
  >
    <template #content>
      <div class="overflow-y-auto px-5">
        <ExpandableList :items="menuItems" />
      </div>
    </template>
    <template #sidebar-bottom>
      <div class="px-5">
        <Btn
          v-if="cta?.display"
          class="w-full mt-5"
          :size="BtnSize.LG"
          :variant="BtnVariant.FILLED"
          :color="BtnColor.TERTIARY"
          :to="cta?.url || ''"
          target="_blank"
        >
          <div class="whitespace-nowrap">
            {{ cta?.text }}
          </div>
        </Btn>
      </div>
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Sidebar } from '~/layouts/_components/sidebar';
import { useOrganizationStore } from '~/store/organization';
import { HubDto } from '~/api/hubs';
import { BtnColor, BtnSize, BtnVariant, Btn } from '~/components/btn';
import { Nullable } from '~/types/common';
import ExpandableList from '~/layouts/_components/right-sidebar/_components/expandable-list/expandable-list.vue';
import { MenuItemDto } from '~/api/organizations';
import { computed } from '#imports';
import { useRoute } from 'nuxt/app';

type Cta = {
  display: boolean;
  text: string;
  url: string;
};

type Props = {
  isOpen: boolean;
  hub: Nullable<HubDto>;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  onBackdropClick: [];
}>();

const organizationStore = useOrganizationStore();
const { organization } = storeToRefs(organizationStore);
const route = useRoute();
const hubSlug = computed<string>(() => route.params.hubslug as string);

const menuItems = computed<MenuItemDto[]>(() => {
  if (!organization.value) {
    return [];
  }
  if (
    route.name === 'promote' &&
    organization?.value?.isPromoteMenuEnabled &&
    organization.value.promoteMenuItems?.length
  ) {
    return organization.value.promoteMenuItems;
  }

  if (hubSlug.value && props.hub?.isMenuEnabled) {
    return props.hub.menuItems;
  }

  if (organization.value.isMenuEnabled) {
    return organization.value.menuItems;
  }
  return [];
});

const cta = computed<Cta | null>(() => {
  if (!organization.value) {
    return null;
  }
  if (route.name === 'promote') {
    return {
      display: organization.value.promoteDisplayCtaButton,
      text: organization.value.promoteCtaButtonText,
      url: organization.value.promoteCtaButtonUrl,
    };
  }
  if (props.hub) {
    return {
      display: props.hub?.displayCtaButton,
      text: props.hub?.ctaButtonText,
      url: props.hub?.ctaButtonUrl,
    };
  }
  return null;
});
</script>
